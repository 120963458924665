
import { defineComponent, reactive, ref, computed, onMounted, PropType, inject, Ref } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useDepartment } from '@/views/main/department/utils';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { any, number, string } from 'vue-types';

type WithChildren = {
  children?: WithChildren[];
};

function reduceInDeep<T>(arr: WithChildren[], callback: (pre: T, item: WithChildren) => any, pre: T, maxlevel = 0, level = 1) {
  return arr.reduce<T>((innerPre, item) => {
    innerPre = callback(innerPre, item);
    if (item.children && item.children.length && maxlevel && level + 1 < maxlevel) innerPre = callback(innerPre, item);
    return innerPre;
  }, pre);
}

export default defineComponent({
  name: 'ChangeDepartmentModal',
  props: {
    adjustfromdepartmentid: {
      type: [Number, String],
      default: '',
    },
  },
  emits: ['complete', 'close'],
  setup(props, ctx) {
    const departmentState = useDepartment();
    const submiting = ref(false);
    const visible = ref(false);
    const recordList = ref<any[]>([]);
    const keyList = ref<any[]>([]);
    const formData = ref<{
      adjustDepartmentIds?: string[],
    }>({
      adjustDepartmentIds: undefined,
    });
    const treeMaxHeight = ref(window.innerHeight - 400);
    const treeDefaultExpandedKeys = ref<any[]>([]);

    const change = (list: any[]) => {
      recordList.value = list;
      visible.value = true;
      treeMaxHeight.value = window.innerHeight - 400;
      departmentState.loadDepartmentTree();
    };

    const onCloseTag = (id: string | number) => {
      recordList.value = recordList.value.filter(item => item.id !== id);
      keyList.value = recordList.value.filter(item => item.id !== id).map(item => item.id);

      ctx.emit('close', keyList.value);
    };

    const closeModal = () => {
      formData.value.adjustDepartmentIds = [];
      visible.value = false;
      ctx.emit('complete');
    };

    const editorFormRef = ref<typeof Form>(null as any);
    const onSubmit = async () => {
      if (recordList.value.length <= 0) return message.error('请选择员工');
      try {
        await editorFormRef.value.validate();
        submiting.value = false;
        const adjustFromDepartmentId = props.adjustfromdepartmentid;
        interface interfaceParams { // 编译成js 没有与之对应的东西 编译为空
          adjustDepartmentIds: any,
          userCode: any;
          adjustFromDepartmentId?: number | string
        }
        const params:interfaceParams = {
          adjustDepartmentIds: formData.value.adjustDepartmentIds,
          userCode: recordList.value.map(item => item.userCode),
        };
        if (adjustFromDepartmentId !== undefined && adjustFromDepartmentId !== null && adjustFromDepartmentId !== '') {
          params.adjustFromDepartmentId = adjustFromDepartmentId;
        }
        await request.put(createApiUrl('/newlinkSass/department/batch-adjustment-department'), params);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      message.success('部门调整完成');
      closeModal();
    };

    const rules = {
      adjustDepartmentIds: [{ required: true, type: 'array', message: '请选择移入的部门', trigger: 'blur' }],
    };

    const filterTreeNode = (inputValue: string, treeNode: any) => treeNode.props.departmentName.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

    onMounted(async () => {
      treeDefaultExpandedKeys.value = reduceInDeep(departmentState.departmentTree.value, (pre, item: any) => {
        pre.push(item.value);
        return pre;
      }, [] as any[], 2);
    });

    return {
      ...departmentState,
      treeMaxHeight,
      treeDefaultExpandedKeys,
      onCloseTag,

      labelCol: { span: 5 },
      wrapperCol: { span: 17, offset: 1 },

      visible,
      submiting,
      recordList,

      formData,
      rules,

      change,
      editorFormRef,
      onSubmit,

      filterTreeNode,
      closeModal,
    };
  },
});
