import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "inviteEmployeesModal__tItleDirection" }
const _hoisted_2 = {
  key: 0,
  class: "inviteEmployeesModal__batchInput"
}
const _hoisted_3 = {
  key: 1,
  class: "inviteEmployeesModal__singleInput"
}
const _hoisted_4 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_enterprise_input = _resolveComponent("app-enterprise-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_Verify = _resolveComponent("Verify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      class: "inviteEmployeesModal",
      title: "邀请员工加入企业",
      width: 420,
      onCancel: _ctx.dialogAfterClose
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          disabled: _ctx.isVerfy,
          onClick: _ctx.btnSendSingleInvitations
        }, {
          default: _withCtx(() => [
            _createTextVNode("发送邀请")
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_app_enterprise_input, {
          value: _ctx.enterpriseId,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enterpriseId) = $event)),
          style: {"margin-bottom":"20px","width":"100%"},
          "disable-in-open": ""
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.addType==='single')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" 通过员工的手机号码/邮箱（仅用于发送邀请），企业审核后，员工加入企业 ")
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" 请输入或批量粘贴新成员的手机号码/邮箱 ")
              ], 64)),
          _createVNode(_component_a_button, {
            type: "link",
            style: {"padding":"0"},
            onClick: _ctx.changeAddModel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.addType === 'single' ? '切换至批量输入' : '切换至单个输入'), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        (_ctx.addType === 'multiple')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_a_textarea, {
                value: _ctx.multipleValue,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.multipleValue) = $event)),
                placeholder: "须使用逗号, 分隔手机号/邮箱",
                "auto-size": { minRows: 10, maxRows: 5 },
                onBlur: _ctx.checkMultipleInfo,
                onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.mulTipsVerfy=false; _ctx.isVerfy=false;})
              }, null, 8, ["value", "onBlur"]),
              _withDirectives(_createElementVNode("span", { style: {"color":"red"} }, _toDisplayString(_ctx.multipleErrMessage), 513), [
                [_vShow, _ctx.mulTipsVerfy]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.addType === 'single')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicValidateForm.domains, (domain, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "inviteEmployeesModal__singleInputAndTipsWarp"
                }, [
                  _createVNode(_component_a_input, {
                    value: domain.value,
                    "onUpdate:value": ($event: any) => ((domain.value) = $event),
                    placeholder: "请输入员工手机号/邮箱",
                    onClick: ($event: any) => (_ctx.onSingleInputFocus(domain)),
                    onBlur: ($event: any) => (_ctx.onSingleInputBlur(domain))
                  }, null, 8, ["value", "onUpdate:value", "onClick", "onBlur"]),
                  _withDirectives(_createElementVNode("span", _hoisted_4, "请输入正确的手机号/邮箱", 512), [
                    [_vShow, domain.verfi]
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onCancel"]),
    _createVNode(_component_Verify, {
      ref: "verify",
      mode: "pop",
      "captcha-type": _ctx.captchaType,
      "img-size": {width:'400px',height:'200px'},
      onSuccess: _ctx.onVerifyPassed
    }, null, 8, ["captcha-type", "onSuccess"])
  ], 64))
}