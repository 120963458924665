
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import { assignWithDefault, dispatchDownload } from '@/utils/illuminate';
import { useUtils } from '@/core/utils';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { formatDate } from '@/core/filters';

export default defineComponent({
  name: 'ImportStaffModal',
  emits: ['complete'],
  setup(props, ctx) {
    const utils = useUtils();
    const visible = ref(false);
    const uploadComplete = ref(false);
    const step = ref(0);

    const onDownload = async (downloadType = 0, importFailKey = '') => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/download/staff/template'), { responseType: 'blob', params: { downloadType, importFailKey } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const fileName = downloadType === 0 ? '员工导入模版.xlsx' : '错误文件.xlsx';
      dispatchDownload(fileName, url);
      URL.revokeObjectURL(url);
    };

    const formData = reactive({
      file: '',
    });

    const defaultRes = {
      fail: 0,
      success: 0,
      importFailKey: '',
    };

    const uploadRes = reactive<{
      fail: number;
      importFailKey: string;
      success: number;
    }>({
      ...defaultRes,
    });

    /* = -------------------------------------------------- = 上传文件 = -------------------------------------------------- = */

    const uploading = ref(false);

    const onUpload = async (event: Event) => {
      const ele = (event.target as HTMLInputElement);
      if (!ele.files!.length) {
        return;
      }

      const file = ele.files![0];

      // 上传文件
      let res: any;
      uploading.value = true;
      step.value = 1;

      try {
        const fd = new FormData();
        fd.append('file', file);
        res = await request.post(createApiUrl('/newlinkSass/excel/import/enterprise/staff'), fd, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 300000,
        });
      } catch (e: Error & any) {
        step.value = 0;
        return message.error(e.message);
      } finally {
        uploading.value = false;
      }

      formData.file = '';
      uploadComplete.value = true;
      assignWithDefault(uploadRes, res.data, defaultRes);
    };

    /* = -------------------------------------------------- = 入口 = -------------------------------------------------- = */
    const show = (item: any) => {
      visible.value = true;
      formData.file = '';
      step.value = 0;
      uploadComplete.value = false;
      Object.assign(uploadRes, defaultRes);
    };

    const onComfirm = () => {
      visible.value = false;
      ctx.emit('complete');
    };

    const onCancel = () => {
      visible.value = uploading.value || false;
    };

    return {
      ...utils,

      labelCol: { span: 7 },
      wrapperCol: { span: 14 },

      visible,
      formData,
      show,
      onComfirm,
      onCancel,

      step,
      uploading,
      uploadComplete,
      uploadRes,
      onUpload,

      onDownload,
    };
  },
});
