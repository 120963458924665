
import { ref, watch, PropType, defineComponent, reactive } from 'vue';
import { message } from 'ant-design-vue';

import InputNumber from '@/components/InputNumber/InputNumber.vue';
import { firstError, isValidateError, toApiUnit } from '@/utils/utils';
import type { AvailableQuotaData, MultipleQuotaAdjustmentProps } from './utils';

const defaultFormData: AvailableQuotaData = {
  direction: 'out',
  adjustmentType: 2,
  adjustmentAmount: '',
};

export default defineComponent({
  name: 'AvailableQuota',
  components: { InputNumber },
  props: {
    actionProps: { type: Object as PropType<MultipleQuotaAdjustmentProps>, default: () => ({}) },
  },
  emits: ['complete', 'cancel'],
  setup(props, ctx) {
    const formData = ref<AvailableQuotaData>({ ...defaultFormData });

    const formRules = {
      adjustmentAmount: [
        { required: true, message: '请输入金额', trigger: 'blur' },
      ],
    };

    const formRef = ref<any>(null as any);
    const onCancel = () => {
      formData.value = { ...defaultFormData };
      ctx.emit('cancel');
    };

    const onSubmit = async () => {
      try {
        await formRef.value.validate();
        await props.actionProps?.onSubmitAvailableQuota({
          ...formData.value,
          adjustmentAmount: toApiUnit(formData.value.adjustmentAmount),
        });
        message.success('操作完成');
        formData.value = { ...defaultFormData };
        ctx.emit('complete', true);
        props.actionProps?.onComplete();
      } catch (e: Error & any) {
        message.error(isValidateError(e) ? firstError(e) : e.message);
        throw e;
      }
    };

    return {
      formData,
      formRules,
      formRef,

      onSubmit,
      onCancel,
    };
  },
});
