import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "position-num" }
const _hoisted_2 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    class: "modifyDepartmentModal",
    title: "调整至部门",
    width: "480px",
    "confirm-loading": _ctx.submiting,
    "mask-closable": false,
    onOk: _ctx.onSubmit,
    onCancel: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "editorFormRef",
        model: _ctx.formData,
        rules: _ctx.rules,
        layout: 'vertical',
        class: "editorFormRef-form",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, [
            _createTextVNode("已选: "),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.recordList.length) + " 人", 1)
          ]),
          _createVNode(_component_a_form_item, {
            label: "请选择移入的部门",
            name: "adjustDepartmentIdsId",
            class: "adjustDepartmentIdsId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_tree_select, {
                value: _ctx.formData.adjustDepartmentIds,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.adjustDepartmentIds) = $event)),
                style: {"width":"280px"},
                "allow-clear": "",
                "show-search": "",
                multiple: "",
                "search-placeholder": "请选择",
                "tree-default-expanded-keys": _ctx.treeDefaultExpandedKeys,
                "show-line": true,
                "filter-tree-node": _ctx.filterTreeNode,
                "tree-data": _ctx.departmentTree,
                "dropdown-style": { maxHeight: `${_ctx.treeMaxHeight}px` }
              }, null, 8, ["value", "tree-default-expanded-keys", "filter-tree-node", "tree-data", "dropdown-style"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "confirm-loading", "onOk", "onCancel"]))
}