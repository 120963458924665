
import { defineComponent, reactive, ref, computed, onMounted, watch, nextTick, PropType, inject, Ref } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { useScene } from '@/views/scene/utils';

export default defineComponent({
  name: 'ChangeRuleModal',
  emits: ['complete', 'close'],
  setup(props, ctx) {
    const sceneState = useScene();
    const visible = ref(false);

    const filterParams = reactive<Record<string, any>>({
      pageSize: 200,
      pageNo: 1,
      pageNum: 1,
      nameOrPhone: '',
    });

    const selectList = ref<{ id: number; name: string; userCode: string }[]>([]);

    const selectedStaff = ref<{ id: number; name: string; userCode: string }[]>([]);
    const selectedStaffKey = ref<number[]>([]);

    const defaultData: {
      confNo?: string;
      userCodes: string[];
    } = {
      confNo: undefined,
      userCodes: [],
    };

    const formData = reactive({
      ...defaultData,
    });

    const rules = {
      userCode: [{ required: true, type: 'array', message: '请选择要分配的用户', trigger: 'change' }],
      confNo: [{ required: true, message: '请选择分配的部门', trigger: 'change' }],
    };

    watch(selectedStaff, val => {
      formData.userCodes = val.map(item => item.userCode);
      selectedStaffKey.value = val.map(itm => itm.id);
    });

    // 修改动作入口
    const change = (recordList: any[] = []) => {
      Object.assign(formData, defaultData);
      selectedStaff.value = recordList.map(item => ({ id: item.id, name: item.userName, userCode: item.userCode }));

      visible.value = true;
      sceneState.loadSceneList();
    };

    const selectRef = ref();
    const handleChange = (val: number) => {
      if (!val) return;
      const rowData: any = selectList.value.filter(item => item.id === val);
      selectedStaff.value.push(...rowData);
      selectedStaffKey.value.push(val);
      formData.userCodes.push(rowData[0].userCode);
      filterParams.nameOrPhone = '';
      ctx.emit('close', selectedStaffKey.value);

      const clearBtn = selectRef.value.$el.querySelector('.ant-select-clear');
      const event = new MouseEvent('mousedown');
      clearBtn && clearBtn.dispatchEvent(event);
    };

    const submiting = ref(false);
    const editorFormRef = ref<any>(null as any);
    const onSubmit = async () => {
      try {
        await editorFormRef.value.validate();

        if (submiting.value) return;
        submiting.value = true;
        await request.put(createApiUrl('/newlinkSass/staff/restrict/staff/bind'), formData);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      message.success('批量设置制度完成');
      visible.value = false;
      ctx.emit('complete');
    };

    const closeModal = () => {
      filterParams.nameOrPhone = '';
      formData.confNo = '';
      ctx.emit('complete');
    };

    const filterOption = (input: string, option: any) => option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return {
      ...sceneState,

      selectList,
      closeModal,

      filterParams,
      selectedStaff,
      selectedStaffKey,

      visible,
      change,

      submiting,
      formData,
      rules,
      editorFormRef,
      onSubmit,

      handleChange,
      filterOption,

      selectRef,
    };
  },
});
