import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MQAAvailableQuota" }
const _hoisted_2 = { class: "MQAModal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_number = _resolveComponent("input-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      ref: "formRef",
      class: "MQAAvailableQuota__container",
      model: _ctx.formData,
      rules: _ctx.formRules
    }, {
      default: _withCtx(() => [
        _createElementVNode("h3", null, "批量将" + _toDisplayString(_ctx.actionProps.activeObjectName) + "的可用额度调整为:", 1),
        _createVNode(_component_a_form_item, { name: "adjustmentAmount" }, {
          default: _withCtx(() => [
            _createVNode(_component_input_number, {
              value: _ctx.formData.adjustmentAmount,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.adjustmentAmount) = $event)),
              class: "finance-input",
              style: {"width":"350px"},
              size: "large",
              placeholder: "请输入正数金额",
              suffix: "元"
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_button, {
        style: {"margin-right":"14px"},
        onClick: _ctx.onCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("取消")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode("保存")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}