
import { ref, computed, defineComponent, PropType, reactive } from 'vue';

import { message, Modal } from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment';
import InputNumber from '@/components/InputNumber/InputNumber.vue';
import { MultipleQuotaAdjustmentProps, RegularQuotaData } from '@/components/MultipleQuotaAdjustment/utils';
import { toApiUnit } from '@/utils/utils';

const defaultFormData: RegularQuotaData = {
  adjustmentType: 2,
  refreshCycle: 2,
  quotaAmount: '',
  inheritFlag: 0,
  timelinessFlag: 0,
  firstDateTime: '',
  activate: 1,
  effectiveDate: null,
};

export default defineComponent({
  components: {
    InputNumber,
    QuestionCircleOutlined,
  },
  props: {
    actionProps: { required: true, type: Object as PropType<MultipleQuotaAdjustmentProps> },
  },
  emits: ['complete', 'cancel'],
  setup(props, ctx) {
    const operateType = ref('detail');
    const showDate = ref(true);

    const formData = ref<RegularQuotaData>({ ...defaultFormData });
    const clearCycle = computed<boolean>({
      get: () => !formData.value.inheritFlag,
      set: val => {
        formData.value.inheritFlag = +!val as RegularQuotaData['inheritFlag'];
      },
    });

    const timeStr = computed(() => {
      if (formData.value.refreshCycle === 1) {
        return moment().date((moment().date() - (moment().day() - 1)) + 7).format('YYYY.MM.DD');
      }
      return moment().month(moment().month() + 1).date(1).format('YYYY.MM.DD');
    });

    const getTime = (yy: number, mm: number, dd: number) => {
      const d = new Date(yy, mm, dd);
      const nowMonth = d.getMonth() + 1;
      const nowDate = d.getDate();
      return `${nowMonth < 10 ? `0${nowMonth}` : nowMonth}.${nowDate < 10 ? `0${nowDate}` : nowDate}`;
    };

    const dateList: number[] = reactive([]);
    for (let i = 1; i <= 25; i++) {
      dateList.push(i);
    }

    const tableList = computed(() => {
      let d = new Date();
      let i = 6;
      const data = [];
      while (i > 0) {
        let nowYear = d.getFullYear();
        let nowMonth = d.getMonth() + 1;
        if (formData.value.refreshCycle === 1) { // 每周
          const nowWeek = d.getDay();
          nowYear = d.getFullYear();
          nowMonth = d.getMonth() + 1;
          let nowDate = d.getDate() - (nowWeek - 1);
          const week = moment([nowYear, nowMonth, nowDate]).week();
          data.push(`${week}周 (${getTime(nowYear, nowMonth - 1, nowDate)}-${getTime(nowYear, nowMonth - 1, nowDate + 6)})`);
          nowDate += 7;
          d = new Date(nowYear, nowMonth - 1, nowDate);
        } else { // 每月
          data.push(`${nowMonth === 1 ? `${nowYear}年` : ''}${nowMonth}月`);
          nowMonth += 1;
          d = new Date(nowYear, nowMonth - 1);
        }
        i--;
      }
      return data;
    });

    const onCancel = () => {
      formData.value = { ...defaultFormData };
      ctx.emit('cancel', true);
    };

    const onSubmit = async () => {
      if (!formData.value.quotaAmount) return message.error('请输入金额');
      if (formData.value.timelinessFlag === 2 && !formData.value.effectiveDate) return message.error('请选择生效日期');

      try {
        await props.actionProps?.onSubmitRegularQuota({
          ...formData.value,
          activate: +formData.value.quotaAmount ? 1 : 0,
          quotaAmount: toApiUnit(formData.value.quotaAmount),
        });
        message.success('操作完成');
        formData.value = { ...defaultFormData };
        ctx.emit('complete', true);
        props.actionProps?.onComplete();
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };
    // 判断自定义生效日期是否在当前日期之后
    function changeEffectDate(value:number|null|undefined):void {
      if (value) {
        const day = new Date().getDate();
        showDate.value = value > day;
      } else {
        showDate.value = false;
      }
    }

    // 修改生效时间
    function changeEffectTime(e:any):void {
      const target:number = e.target.value;
      if (target === 2) {
        changeEffectDate(formData.value.effectiveDate);
      } else {
        showDate.value = true;
      }
    }

    return {
      operateType,
      timeStr,
      tableList,
      formData,
      clearCycle,
      dateList,
      showDate,

      onCancel,
      onSubmit,
      changeEffectDate,
      changeEffectTime,
    };
  },
});
