
import { defineComponent, computed, reactive, ref, watch, createVNode, provide, readonly, onUnmounted, ComponentPublicInstance, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { message, Modal } from 'ant-design-vue';
import Axiox, { CancelTokenSource } from 'axios';
import { DownOutlined, ExclamationCircleFilled, DownCircleOutlined } from '@ant-design/icons-vue';
import { noEmptyProp, dispatchDownload, formatWithIntl, insert } from '@/utils/illuminate';
import type { TableColumn, TableOptions } from '@/components/Table';

import { request } from '@/utils/request';
import { useCurrentEnterpriseHook, useState } from '@/store';
import router from '@/router';
import ImportStaffModal from '@/views/main/staff/parts/ImportStaffModal.vue';
import InviteEmployeesModal from '@/views/main/staff/parts/InviteEmployeesModal.vue';
import QuotaAdjustment from '@/components/QuotaAdjustment/modal.vue';
import UserInputNew from '@/components/UserInput/UserInputNew.vue';
import { createApiUrl, sldPageTitle } from '@/utils/utils';

import { EnterpriseItem } from '@/views/parts/subsidiary';
import MultipleQuotaAdjustmentModal from '@/components/MultipleQuotaAdjustment/Modal.vue';
import { AvailableQuotaData, MultipleQuotaAdjustmentProps, RegularQuotaData } from '@/components/MultipleQuotaAdjustment/utils';
import { useStaff } from '@/views/main/staff/utils';
import ChangeRuleModal from './parts/ChangeRuleModal.vue';
import ChangeDepartmentModal from './parts/ChangeDepartmentModal.vue';
import ConfirmCreditModal from './parts/ConfirmCreditModal.vue';
import DepartmentMenuNew from '../parts/DepartmentMenuNew.vue';

import AddStaff from './parts/AddStaff.vue';
import AddUser from './parts/AddUser.vue';
import StaffDetail from './parts/StaffDetail.vue';

export default defineComponent({
  name: 'StaffListView',
  components: {
    MultipleQuotaAdjustmentModal,
    QuotaAdjustment,
    ImportStaffModal,
    ChangeRuleModal,
    ChangeDepartmentModal,
    ConfirmCreditModal,
    DepartmentMenuNew,
    DownOutlined,
    DownCircleOutlined,
    InviteEmployeesModal,
    StaffDetail,
    UserInputNew,
    AddStaff,
    AddUser,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    useCurrentEnterpriseHook();
    const state = useState();
    const store = useStore();
    const staffState = useStaff();
    const isScheduleModel = !store.getters['User/isScheduleModel'];
    const filterParams = reactive<{
      userCode: string | undefined;
      nameOrPhone: string | undefined;
      enterprisePayState: string;
      departmentId?: number | string;
      temporary: boolean;
      state: boolean;
    }>({
      userCode: undefined,
      nameOrPhone: undefined,
      enterprisePayState: '',
      departmentId: undefined,
      temporary: false,
      state: false,
    });

    const showAddUser = ref(state.enterprise.value.supportPrepaidCard);

    const authority = ref(store.state.User.authority);

    const inviteUserNum = computed(() => (store.state.User.inviteUserNum));
    const localEnterpriseName = ref('');
    const obdStatus = ref(false);
    const addModelType = ref('single');
    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);
    const deptAllChildNodeFlag = ref(false);
    const inputSelectFlag = ref(false);

    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态
    const allDataState = ref(false); // 全选状态
    const allFindList = ref([]); // 全选员工usercode

    // 点击列表checkbox事件
    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRows.value = selectedRows;
      const arr: any = [];
      if (allDataState.value && dataState.value) {
        dataState.value = false;
        dataPage.value.map((item: any) => { arr.push(item.id); });
        tableSelectedRowKeys.value = arr;
      } else {
        tableSelectedRowKeys.value = selectedRowKeys;
      }
    };

    // 获取全部员工数据
    const allFindData = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-user-list'), {
          params: {
            ...noEmptyProp(filterParams),
            deptAllChildNodeFlag: +deptAllChildNodeFlag.value || '',
          },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const arr: any = [];
      res.data.map((item: any) => { arr.push(item.id); });
      tableSelectedRowKeys.value = arr;
      tableSelectedRows.value = res.data;
    };

    const tableOptions = computed<TableOptions>(() => ({
      // 不晓得干啥的，但是不引用可以使用自定义点击
      alert: { show: true, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          disabled: record.state === 'QUIT',
          name: record.name,
        }),
        hideDefaultSelections: true,
        /* @at 2021-12-02 暂时禁用跨页全选功能 */
        // selections: [
        //   {
        //     key: 'all-data-page',
        //     text: '全选当页',
        //     onSelect: (selectedRowKeys: any[]) => {
        //       tableSelectedRowKeys.value = selectedRowKeys;
        //       tableSelectedRows.value = dataPage.value;
        //       allDataState.value = false;
        //     },
        //   },
        //   {
        //     key: 'all-data',
        //     text: '全选全部',
        //     onSelect: (selectedRowKeys: any[]) => {
        //       allDataState.value = true;
        //       allFindData();
        //     },
        //   },
        // ],
      },
    }));

    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '姓名', dataIndex: 'userName', ellipsis: true },
        { title: '部门', slots: { customRender: 'departmentName' }, width: '200px' },
        { title: '员工状态', slots: { customRender: 'state' }, width: '100px' },
        { title: '手机号码', dataIndex: 'phone' },
        { title: '可用额度', dataIndex: 'availableAmount', customRender: ({ text }) => (text ? `${text / 100}` : '') },
        { title: '状态', dataIndex: 'accountStatus', width: '80px', customRender: ({ text }) => (text === 1 ? '正常' : '禁用') },
        { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
      ];

      const obdBasic: TableColumn[] = [
        { title: '姓名', dataIndex: 'userName', ellipsis: true },
        { title: '部门', slots: { customRender: 'departmentName' }, width: '200px' },
        { title: '员工状态', slots: { customRender: 'state' }, width: '100px' },
        { title: '手机号码', dataIndex: 'phone' },
        { title: '可用额度', dataIndex: 'availableAmount', width: '150px', customRender: ({ text }) => (text ? `${text / 100}` : '') },
        { title: 'OBD序列号', dataIndex: 'obdCode' },
        { title: '状态', dataIndex: 'accountStatus', width: '80px', customRender: ({ text }) => (text === 1 ? '正常' : '禁用') },
        { title: '操作', dataIndex: 'action', width: '250px', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      const columns = obdStatus.value ? obdBasic : basic;
      if (!store.getters['User/isScheduleModel']) {
        insert(columns, 4, {
          title: '定期额度',
          dataIndex: 'quota',
          width: '150px', 
          customRender: ({ text, record }) => (record.activate ? `${(['', '每周', '每月'])[record.refreshCycle] || ''} ${formatWithIntl(record.quotaAmount / 100)}` : '--'),
        } as TableColumn);
      }

      return columns;
    });

    const totalNum = ref<string | number>(0);
    const getTemporaryUser = async () => {
      const { code, data } = await request.post(
        createApiUrl('/newlinkSass/enterprise/findTempAccountList'),
        { enterpriseId: state.enterprise.value.enterpriseId },
      );
      if (code !== 200) return;
      totalNum.value = data.length;
      dataPage.value = data;
      data.list = data;
      return data;
    };
    const showButtons = ref<Boolean>(true);
    const loadTableData = async (parameter: any) => {
      if (filterParams.departmentId === 'temporaryUser') {
        showButtons.value = false;
        return getTemporaryUser();
      }
      try {
        showButtons.value = true;
        totalNum.value = '---';
        const res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-user-list'), {
          params: {
            ...parameter,
            ...noEmptyProp(filterParams),
            state: (filterParams.state || inputSelectFlag.value) ? '' : 'ON',
            deptAllChildNodeFlag: +deptAllChildNodeFlag.value || '',
          },
        });
        totalNum.value = res.data.total;
        dataPage.value = res.data.list; // 当前Table数据
        dataState.value = true; // 数据第一次加载
        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const tableRef = ref<any>(null as any);

    const onRefreshTable = () => {
      allDataState.value = false;
      tableSelectedRowKeys.value = [];
      tableSelectedRows.value = [];
      tableRef.value.refresh(true);
    };

    const quotaAdjustRef = ref<any>(null);
    const onQuotaAdjust = (record: any) => {
      quotaAdjustRef.value.showModal({ type: 'person', val: record });
    };

    /* = ---------------------------- = 批量tiaozheng = ---------------------------- = */
    const multipleQuotaAdjustmentRef = ref<any>(null);
    const onSubmitAvailableQuota = async (data: AvailableQuotaData) => request.post(createApiUrl('/newlinkSass/quota/ar/batch/update/available/amount/V2.0'), {
      ...data, userCodeList: tableSelectedRowKeys.value,
    });
    const onSubmitRegularQuota = async (data: RegularQuotaData) => request.post(createApiUrl('/newlinkSass/quota/ar/batch/editStaffQuotaRule'), {
      ...data, userCodeList: tableSelectedRowKeys.value,
    });
    const onAdjustmentQuotaCancel = () => {
      tableSelectedRows.value = [];
      tableSelectedRowKeys.value = [];
      onRefreshTable();
    };
    const onAdjustDepartment = () => {
      if (tableSelectedRows.value.length <= 0) return message.error('请先选择员工');
      multipleQuotaAdjustmentRef.value.adjustmentFor({
        addtional: `(已选 ${tableSelectedRows.value.length} 人)`,
        activeObject: 'staff',
        activeObjectName: '员工',
        onCancel: onAdjustmentQuotaCancel,
        onComplete: onAdjustmentQuotaCancel,
        onSubmitAvailableQuota,
        onSubmitRegularQuota,
      } as MultipleQuotaAdjustmentProps);
    };

    const onAddQuota = async (record: any) => {
      sessionStorage.setItem('quota_type', record.key === '1' ? 'general' : 'regular');
      sessionStorage.setItem('currentEnterpriseId', store.state.User.currentEnterpriseId || state.enterprise.value.enterpriseId);
      router.push({ name: 'main.staff.quota_adjustment' });
    };

    // = ---------------------------- = 切换员工状态 = ---------------------------- =
    const onSwitchUserStatus = async (record: any) => {
      Modal.confirm({
        title: record.accountStatus === 1 ? '确认禁用该员工的账户？' : '确认恢复使用该账户?',
        content: record.accountStatus === 1 ? '禁用后，该员工将无法登录' : '',
        icon: createVNode(ExclamationCircleFilled),
        class: 'noneFooterBorder',
        async onOk() {
          try {
            await request.post(createApiUrl('/newlinkSass/enterprise/update-account-status'), {
              userCode: record.userCode,
              accountStatus: record.accountStatus === 1 ? 2 : 1,
            });
          } catch (e: Error & any) {
            message.error(e.message);
            throw e;
          }

          onRefreshTable();
        },
      });
    };

    const onChangeKey = (selectedRowKeys: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = tableSelectedRows.value.filter((item: any) => selectedRowKeys?.indexOf(item.id) !== -1);
      tableRef.value.updateSelect(tableSelectedRowKeys.value, tableSelectedRows.value);
    };

    const departmentMenu = ref<any>(null as any);
    const onSetDepartment = (id: number, title: string) => {
      if (id < 0) {
        filterParams.departmentId = undefined;
        filterParams.temporary = false;
      } else if (id === 0) {
        filterParams.departmentId = undefined;
        filterParams.temporary = true;
      } else {
        filterParams.departmentId = id;
        filterParams.temporary = false;
        filterParams.userCode = undefined;
        filterParams.nameOrPhone = undefined;
      }

      localEnterpriseName.value = title;
      onRefreshTable();
    };

    const onSearchUser = (val: string) => {
      if (val === filterParams.userCode) return;
      filterParams.userCode = val;
      filterParams.nameOrPhone = undefined;
      inputSelectFlag.value = !!val;
      departmentMenu.value.resetDepartment();
    };

    const onSearchUserText = (val: string) => {
      if (val === filterParams.nameOrPhone) return;
      filterParams.nameOrPhone = val;
      filterParams.userCode = undefined;
      inputSelectFlag.value = !!val;
      departmentMenu.value.resetDepartment();
    };

    const onChangeCompany = (item: EnterpriseItem) => {
      store.commit('User/setCurrentEnterprise', item);
      store.commit('User/setCurrentEnterpriseId', item.enterpriseId);
      filterParams.userCode = undefined;
      filterParams.nameOrPhone = undefined;
      filterParams.departmentId = undefined;
      onSetDepartment(-1, '');
    };

    const addStaffVisible = ref<any>(false);
    // 添加员工
    const addStaff = () => {
      addStaffVisible.value = true;
    };
    const addUserVisible = ref<any>(false);
    // 添加用户
    const addUser = () => {
      addUserVisible.value = true;
    };

    const addStaffComplete = (flag: any, number: number) => {
      addStaffVisible.value = false;
      addUserVisible.value = false;
      if (flag) { 
        Modal.success({
          title: '添加成功',
          content: `已成功添加${number}名员工`,
          onOk() {
            departmentMenu.value.resetDepartment();
          },
        });
      }
    };

    const changeDepartmentModalRef = ref<any>(null as any);
    const onChangeDepartment = () => {
      if (!tableSelectedRows.value.length) return message.error('请先选择员工');
      changeDepartmentModalRef.value.change(tableSelectedRows.value);
    };

    const confirmCreditModalRef = ref<any>(null as any);
    const onCreateItem = async () => {
      if (
        state.enterprise.value.state !== 'SUCCESS'
        && state.enterprise.value.accountNum >= state.enterprise.value.killNum
      ) {
        confirmCreditModalRef.value.show();
      } else {
        router.push({ name: 'main.staff.create' });
      }
    };

    const changeRuleRef = ref<any>(null as any);
    const onChangeRule = () => {
      if (tableSelectedRows.value.length <= 0) return message.error('请先选择员工');
      changeRuleRef.value.change(tableSelectedRows.value);
    };

    // 倒入导出列表
    const importStaffRef = ref<any>(null as any);
    const onImport = async () => {
      importStaffRef.value.show();
    };

    // 手机号/邮箱邀请
    const inviteEmployeesRef = ref<any>(null as any);
    const onInviteEmployees = async (type: string) => {
      inviteEmployeesRef.value.show();
      addModelType.value = type;
    };

    /* = -------------------------------------------------- = 导出员工 = -------------------------------------------------- = */
    const exporting = ref(false);
    let exportSource: CancelTokenSource | null;
    const onExport = async () => {
      exporting.value = true;
      let res: any;
      message.success('开始导出');
      try {
        exportSource = Axiox.CancelToken.source();
        res = await request.get(createApiUrl('/newlinkSass/excel/export/enterprise/staff'), {
          responseType: 'blob',
          timeout: 300000,
          cancelToken: exportSource.token,
          params: {
            state: filterParams.state ? '' : 'ON',
          },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        exporting.value = false;
        exportSource = null;
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      dispatchDownload('员工列表.xlsx', url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      message.success('导出完成');
    };

    const refDrawerOfDetail = ref<any>(null);
    const showDrawerOfDetail = (record: Record<string, any>) => {
      refDrawerOfDetail.value.showDrawer(record.id, record.userCode);
    };

    const handleMenuClick = () => {
      tableRef.value.refresh(true);
    };

    const getObdStatus = async (id: any) => {
      try {
        const res = await request.get(createApiUrl('/newlinkSass/enterprise/find-obd-status'), {
          params: { enterpriseId: id || state.enterprise.value.enterpriseId },
        });
        obdStatus.value = res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    watch(() => filterParams.state, () => {
      onRefreshTable();
    });

    onMounted(() => {
      getObdStatus(state.enterprise.value.enterpriseId);
      instance?.$sensors.track('es_saas_staff_page_view', { page_name: '员工页_浏览' });
    });

    onUnmounted(() => {
      // 取消未完成的导出操作
      exportSource?.cancel();
    });

    return {
      authority,
      stateOptions: staffState.stateOptions,

      getObdStatus,
      obdStatus,
      inviteUserNum,
      filterParams,
      tableSelectedRowKeys,
      tableSelectedRows,
      totalNum,

      tableOptions,
      tableColumns,

      quotaAdjustRef,
      onQuotaAdjust,

      multipleQuotaAdjustmentRef,
      onAddQuota,
      isScheduleModel,
      onChangeKey,
      loadTableData,

      tableRef,
      onRefreshTable,
      confirmCreditModalRef,
      onCreateItem,
      // onInvite: inviteState.onGotoInvite,

      // 部门相关逻辑
      departmentMenu,
      onSetDepartment,
      changeDepartmentModalRef,
      onChangeDepartment,
      onChangeCompany,
      onSearchUser,
      onSearchUserText,

      // 制度相关
      changeRuleRef,
      onChangeRule,

      // 启用禁用
      onSwitchUserStatus,

      // 导入导出数据
      importStaffRef,
      onImport,
      exporting,
      onExport,
      onAdjustDepartment,
      // 手机号/邮箱邀请
      onInviteEmployees,
      inviteEmployeesRef,
      addModelType,
      showDrawerOfDetail,
      refDrawerOfDetail,

      handleMenuClick,
      deptAllChildNodeFlag,

      dataPage,
      dataState,
      allDataState,
      allFindList,
      allFindData, // 获取全部员工数据
      addStaff,
      addUser,
      addStaffVisible,
      addUserVisible,
      addStaffComplete,

      showAddUser, // 是否支持汇元预付卡的公司
      showButtons,
      sldPageTitle,
    };
  },
});
