
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue';
import { Modal, message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import AvailableQuota from './Available.vue';
import RegularQuota from './Regular.vue';


import { AvailableQuotaData, MultipleQuotaAdjustmentProps, RegularQuotaData } from './utils';

export default defineComponent({
  name: 'MultipleQuotaAdjustmentModal',
  components: {
    AvailableQuota,
    RegularQuota,
  },
  setup(props, ctx) {
    const visible = ref(false);
    const activeTab = ref('available');
    const quotaFlag = ref(0);

    const actionProps = ref<MultipleQuotaAdjustmentProps>({
      addtional: '',
      activeObject: 'staff',
      activeObjectName: '员工',
      onComplete: () => null,
      onSubmitAvailableQuota(data: AvailableQuotaData): Promise<void> {
        throw new Error('Function not implemented.');
      },
      onSubmitInfiniteQuota(): Promise<void> {
        throw new Error('Function not implemented.');
      },
      onSubmitRegularQuota(data: RegularQuotaData): Promise<void> {
        throw new Error('Function not implemented.');
      },
    });

    const adjustmentFor = (props: MultipleQuotaAdjustmentProps) => {
      actionProps.value = props;
      visible.value = true;
    };

    const availableQuotaRef = ref<any>(null as any);
    const regularQuotaRef = ref<any>(null as any);

    const onCancel = () => {
      visible.value = false;
      quotaFlag.value = 0;
      activeTab.value = 'available';
    };

    const onComplete = () => {
      visible.value = false;
    };

    const onSubmit = async () => {
      actionProps.value?.onSubmitInfiniteQuota();
    };

    return {
      visible,
      activeTab,
      quotaFlag,
      actionProps,

      availableQuotaRef,
      regularQuotaRef,

      adjustmentFor,
      onCancel,
      onComplete,
      onSubmit,
    };
  },
});
