
import { defineComponent, computed, ref, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import moment from 'moment';
import { not } from '@/utils/illuminate';

import { useStaff } from '@/views/main/staff/utils';
import { useDepartment } from '@/views/main/department/utils';
import { useState } from '@/store';
import { useAuto } from '@/views/auto/utils';
import { useScene } from '@/views/scene/utils';
import { useRole } from '@/views/role/utils';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import { request } from '@/utils/request';

export default defineComponent<{}, {}, {}, {}, any>({
  name: 'EditStaffView',
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const state = useState();
    const staffState = useStaff();
    const departmentState = useDepartment();
    const autoState = useAuto();
    const sceneState = useScene();
    const roleState = useRole();
    const submiting = ref(false);
    const isInit = ref(false); // 是否为"初始管理员"
    let initRoleId = 0;

    let origin: any;

    const formData = reactive({
      ...staffState.basicFormData,
      ...staffState.payFormData,
    });

    const rules = {
      ...staffState.createBasicInfoRules(formData),
      ...staffState.createPayInfoRules(formData),
    };

    const loadData = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/staff/find-account-detail'), {
          params: { staffId: route.params.id },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      origin = res.data;

      Object.assign(formData, not(res.data, ['departments', 'enterprisePay', 'vehicleList', 'ruleList', 'roles']));
      formData.departmentId = res.data.departments.length ? res.data.departments[0].departmentId : '';
      formData.enterprisePay = res.data.enterprisePay === 'OPEN';
      formData.roleId = res.data.roles.map((item: any) => {
        if (item.initStatus === 'INIT') {
          initRoleId = item.id;
          isInit.value = true;
        }
        return item.id;
      });
      formData.ruleId = res.data.ruleList.length ? res.data.ruleList[0].id : '';
      formData.vehicleId = res.data.vehicleList.map((item: any) => item.vehicleId);
    };

    const formRef = ref<any>(null as any);
    const onSubmit = () => {
      if (submiting.value) return;

      formRef.value.validate()
        .then(() => {
          submiting.value = true;
          const data = {
            ...formData,
            delVehicleId: origin.vehicleList.reduce((pre: any[], item: any) => {
              if (formData.vehicleId.indexOf(item.vehicleId) === -1) pre.push(item.vehicleRelId);
              return pre;
            }, []),
            delRoleId: origin.roles.reduce((pre: any[], item: any) => {
              if (formData.roleId!.indexOf(item.id) === -1) pre.push(item.userRoleRelId);
              return pre;
            }, []),
            delRuleId: origin.ruleList.reduce((pre: any[], item: any) => {
              if (formData.roleId !== item.id) pre.push(item.ruleUserRelId);
              return pre;
            }, []),
          };

          if (isInit.value) delete data.roleId;

          return staffState.submitStaffData(data);
        })
        .then(() => {
          message.success('员工编辑完成');
          router.push({ name: 'main.staff.list' });
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    onMounted(() => {
      loadData();
      departmentState.loadDepartmentTree();
      autoState.loadAutoList();
      roleState.loadRoleList();
      sceneState.loadSceneList();
    });

    return {
      ...not(staffState, ['createBasicInfoRules', 'createPayInfoRules']),
      ...roleState,
      ...departmentState,
      ...autoState,
      ...sceneState,

      isInit,
      submiting,
      formData,
      rules,

      formRef,
      onSubmit,
    };
  },
});
