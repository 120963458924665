
import { defineComponent, ref, reactive, onMounted, watch, watchEffect, inject, Ref } from 'vue';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import { FormOutlined, QuestionCircleFilled } from '@ant-design/icons-vue';
import { mobileReg } from '@/utils/illuminate';

import { createApiUrl, firstError, handlerTree, isValidateError } from '@/utils/utils';
import { request, axiosAll, axiosSpread } from '@/utils/request';

type BasicFormData = {
  userName: string;
  phone: string;
  jobNumber: string;
  departmentName: string;
  departmentId: string;
  departments: string[];
  departmentIds: string[];
  departmentStrArr: string[];
  roleId: string[];
  roleNames: string[];
  state: string;
  oilSupplementFlag: number;
  userCode: string;
  enterpriseId: number;
  afterStaffQuotaRule: number | string;
  createDate: number | string;
};

type VehicleInfos = {

};
type ObdInfos = {
  obdCode: string;
  iccId: string | undefined,
  openingPeriodDate: Moment | string,
  firstActivationTime: string,
  actualBillingStartTime: string,
  expirationTime: string,
  userCode: string,
}
const defalutVehicleInfos = {

};

const defaultBasicFormData = {
  userName: '',
  phone: '',
  jobNumber: '',
  departmentName: '',
  departmentId: '',
  departments: [],
  departmentIds: [],
  departmentStrArr: [],
  roleId: [],
  roleNames: [],
  state: 'ON',
  oilSupplementFlag: 1,
  userCode: '',
  enterpriseId: 0,
  afterStaffQuotaRule: '',
  createDate: '',
};

const defaultObdInfos = {
  obdCode: '',
  iccId: undefined,
  openingPeriodDate: '',
  firstActivationTime: '',
  actualBillingStartTime: '',
  expirationTime: '',
  userCode: '',
};

export default defineComponent({
  components: {
    FormOutlined,
    QuestionCircleFilled,
  },
  emits: ['complete'],
  setup(props, ctx) {
    /* 变量定义区域 */
    const store = useStore();
    const isAdministrator = ref(false);
    const refBaseForm = ref();
    const drawerTitle = ref('员工详情');
    let employuserCode = '';
    const employId = ref(0);
    const drawerVisible = ref(false); // drawer显示与隐藏
    const dataLoading = ref(false);
    const isEditBaseInfo = ref(false);
    const obdStatus = ref(false);
    const formRef = ref();

    // 基础信息
    const basicFormData = ref<BasicFormData>({
      ...defaultBasicFormData,
    });
    let oldFormData: BasicFormData = { ...defaultBasicFormData };

    // 车辆信息
    const vehicleInfos = ref<VehicleInfos>({
      ...defalutVehicleInfos,
    });

    const usingCarType = ref(1);

    // obd信息
    const obdInfos = ref<ObdInfos>({
      ...defaultObdInfos,
    });

    const phoneValidator = async (rule: string, value: any) => {
      if (value === '') {
        return Promise.reject('请输入手机号');
      }
      return mobileReg.test(value) ? Promise.resolve() : Promise.reject('请输入正确的手机号');
    };
    const ObdCodeValidator = async (rule: string, value: any) => {
      if ((obdInfos.value.iccId || obdInfos.value.openingPeriodDate) && !value) return Promise.reject('请先填写OBD序列号');
      return Promise.resolve();
    };
    const iccIdValidator = async (rule: string, value: any) => {
      if (obdInfos.value.openingPeriodDate && !value) return Promise.reject('请先填写卡号');
      return Promise.resolve();
    };

    // 基础信息验证规则
    const rulesBaseInfo = {
      userName: [
        { required: true, message: '请输入姓名', trigger: 'blur' },
        { max: 32, message: '姓名不超过 32 位', trigger: 'change' },
      ],
      phone: [
        { required: true, validator: phoneValidator, trigger: 'change' },
      ],
    };

    const rulesObdInfo = {
      obdCode: [
        { pattern: /^[a-zA-Z0-9]{0,20}$/, message: '只能输入数字和字母，长度限制为20位', trigger: 'blur' },
        { validator: ObdCodeValidator, trigger: 'blur' },
      ],
      iccId: [
        { validator: iccIdValidator, trigger: 'blur' },
      ],
    };

    // 定期额度
    const lineInfo = reactive<{
      activate: 1; // 是否开启
      inheritFlag: 0; // 剩余额度是否累计
      refreshCycle: 1; // 刷新周期 1-周 2-月
    }>({
      activate: 1, // 是否开启
      inheritFlag: 0, // 剩余额度是否累计
      refreshCycle: 1, // 刷新周期 1-周 2-月
    });

    // 下拉选择框数据
    const departmentList = ref([]); // 部门列表
    const roleList = ref([]); // 角色列表
    const roleListBack = ref([]);
    const autoList = ref<{ id: number }[]>([]); // 车辆列表
    const processedAutoList = ref<{ id: number }[]>([]); // 处理过的车辆列表
    const restrictList = ref([]); // 制度列表
    const isEditAutoInfo = ref(false); // 车辆信息是否可编辑
    const choisedAutoIds = ref<number[]>([]); // 选中的车牌列表
    const choisedAutoListOnShow = ref<any[]>([]);
    const isEditRestInfo = ref(false);
    const isEditObdInfo = ref(false);
    const restInfo = reactive({ id: 0, name: '' });
    const iccIdList = ref([]); // 卡号下拉框

    watch(() => obdInfos.value.iccId, () => {
      if (!obdInfos.value.iccId) obdInfos.value = { ...defaultObdInfos, obdCode: obdInfos.value.obdCode, userCode: obdInfos.value.userCode };
      else if (isEditObdInfo.value) {
        const cur = iccIdList.value.find((item: ObdInfos) => item.iccId === obdInfos.value.iccId) || { ...defaultObdInfos };
        obdInfos.value.iccId = cur.iccId;
        obdInfos.value.openingPeriodDate = cur.openingPeriodDate ? moment(cur.openingPeriodDate).format('YYYY-MM-DD') : '';
        obdInfos.value.firstActivationTime = cur.firstActivationTime ? moment(cur.firstActivationTime).format('YYYY-MM-DD') : '';
        obdInfos.value.actualBillingStartTime = cur.actualBillingStartTime ? moment(cur.actualBillingStartTime).format('YYYY-MM-DD') : '';
        obdInfos.value.expirationTime = cur.expirationTime ? moment(cur.expirationTime).format('YYYY-MM-DD') : '';
      }
    });
    watchEffect(() => {
      if (isEditBaseInfo.value || isEditAutoInfo.value || isEditRestInfo.value || isEditObdInfo.value) {
        drawerTitle.value = '编辑员工';
      } else {
        drawerTitle.value = '员工详情';
      }
    });

    const onFilterAutoList = (inputValue: string, option: any) => (option.children[0].children || '').indexOf(inputValue) !== -1;
    // 卡号模糊搜索
    const handleSearch = (value: string) => {
      if (value) {
        request.get(createApiUrl('/newlinkSass/staff/sim/get-list-like-iccid'), { params: { iccIdLike: value } }).then((res: any) => {
          const { data, code } = res;
          if (code !== 200) return message.error(res.message);
          iccIdList.value = data;
        });
      }
    };

    const computeLoadData = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/quota/ar/getStaffDetailInfo'), { params: { staffId: employId.value } });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        setTimeout(() => {
          dataLoading.value = false;
        }, 500);
      }

      const d = res.data;
      usingCarType.value = d.usingCarType;
      const formData = {
        ...d,
        roleId: [],
        roleNames: [],
        departmentIds: d.selectedDepartmentIds || [],
        departmentStrArr: d.departmentsStr ? d.departmentsStr.split(',') : [],
      };
      basicFormData.value = formData;
      oldFormData = { ...formData };

      d.roles.forEach((item: any) => {
        if (item.initStatus === 'INIT' && item.roleName === '初始管理员') {
          isAdministrator.value = true;
        }
        basicFormData.value.roleId.push(item.id);
        basicFormData.value.roleNames.push(item.roleName);
      });

      basicFormData.value.state = d.state;
      obdInfos.value.obdCode = d.obdCode;
      obdInfos.value.userCode = d.userCode;
      if (d.obdSimInfo) {
        const { iccId, openingPeriodDate, firstActivationTime, actualBillingStartTime, expirationTime } = d.obdSimInfo;
        obdInfos.value.iccId = iccId;
        obdInfos.value.openingPeriodDate = openingPeriodDate ? moment(openingPeriodDate).format('YYYY-MM-DD') : '';
        obdInfos.value.firstActivationTime = firstActivationTime ? moment(firstActivationTime).format('YYYY-MM-DD') : '';
        obdInfos.value.actualBillingStartTime = actualBillingStartTime ? moment(actualBillingStartTime).format('YYYY-MM-DD') : '';
        obdInfos.value.expirationTime = expirationTime ? moment(expirationTime).format('YYYY-MM-DD') : '';
        handleSearch(iccId);
      }
      obdStatus.value = d.openObd;

      lineInfo.activate = d.activate ? d.activate : false;
      lineInfo.inheritFlag = d.inheritFlag ? d.inheritFlag : 0;
      lineInfo.refreshCycle = d.refreshCycle ? d.refreshCycle : 1;
      if (d.bindVehicleInfoList && d.bindVehicleInfoList.length) {
        (choisedAutoIds.value as number[]) = d.bindVehicleInfoList.map((item: any) => item.id);
        processedAutoList.value = autoList.value.map(item => ((choisedAutoIds.value as number[]).includes(item.id) ? { ...item, num: 0 } : item));
        choisedAutoListOnShow.value = d.bindVehicleInfoList;
      }
      restInfo.id = d.restrictConfId;
      restInfo.name = d.titleName;
    };

    // 加载基础数据
    function loadDataById() {
      dataLoading.value = true;
      axiosAll([
        request.get(createApiUrl('/newlinkSass/quota/ar/getDepartmentInfo')),
        request.get(createApiUrl('/newlinkSass/staff/restrict/conf/list')),
        request.get(createApiUrl('/newlinkSass/quota/ar/getRoleInfo')),
        request.get(createApiUrl('/newlinkSass/vehicle/get/enterprise/vehicle/list'), { params: { account: employuserCode } }),
      ]).then(axiosSpread((departData, restrictData, roleData, autoRes) => {
        const dDataTemp = departData.data.map((item: any) => ({
          label: item.departmentName,
          value: item.id,
          pid: item.parentDepartmentId,
        }));
        departmentList.value = handlerTree(dDataTemp, 'value', 'pid', '', 0);
        roleList.value = roleData.data;
        roleListBack.value = roleData.data;
        autoList.value = autoRes.data;
        processedAutoList.value = autoRes.data;
        restrictList.value = restrictData.data.map((item: any) => ({ confNo: item.confNo, title: item.title }));
        computeLoadData();
      }))
        .finally(() => {
          setTimeout(() => {
            dataLoading.value = false;
          }, 500);
        });
    }

    // 打开 drawer
    const showDrawer = (id: number, userCode: string) => {
      employId.value = id;
      employuserCode = userCode;
      choisedAutoIds.value = [];
      choisedAutoListOnShow.value = [];
      obdInfos.value.obdCode = '';
      drawerVisible.value = true;
      obdInfos.value = { ...defaultObdInfos };
      basicFormData.value = { ...defaultBasicFormData };
      oldFormData = { ...defaultBasicFormData };
      loadDataById();
    };

    // 关闭 drawer
    const fnDrawerClose = () => {
      basicFormData.value.departmentId = '';
      isAdministrator.value = false;
      isEditBaseInfo.value = false;
      isEditAutoInfo.value = false;
      isEditRestInfo.value = false;
      isEditObdInfo.value = false;
      drawerVisible.value = false;
    };

    // 点击编辑后取消
    const editCancel = (type: string, reloadList = true) => {
      switch (type) {
        case 'isEditBaseInfo':
          computeLoadData();
          basicFormData.value.departmentId = '';
          isEditBaseInfo.value = false;
          break;
        case 'isEditAutoInfo':
          computeLoadData();
          isEditAutoInfo.value = false;
          break;
        case 'isEditRestInfo':
          computeLoadData();
          isEditRestInfo.value = false;
          break;
        case 'isEditObdInfo':
          computeLoadData();
          isEditObdInfo.value = false;
          formRef.value.resetFields();
          break;
        default:
          break;
      }
      if (reloadList) {
        ctx.emit('complete');
      }
    };


    /* = ---------------------------- = 保存基础数据 = ---------------------------- = */
    const onSaveBasicInfo = async () => {
      try {
        await refBaseForm.value.validate();
        const params: any = {
          ...basicFormData.value,
          id: employId.value,
        };

        await request.put(createApiUrl('/newlinkSass/quota/ar/editStaffInfo'), params);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }

      oldFormData = { ...basicFormData.value };
      message.success('信息编辑成功');
      editCancel('isEditBaseInfo');
    };

    const isQuitFormVisible = ref(false);
    const onQuitFormConfirm = () => {
      isQuitFormVisible.value = false;
      onSaveBasicInfo();
    };
    const onQuitFormCancel = () => {
      isQuitFormVisible.value = false;
      basicFormData.value.state = 'ON';
    };

    // 点击基础数据保存按钮保存
    const onSubmitBasicInfo = async () => {
      if (oldFormData.state === 'ON' && basicFormData.value.state === 'QUIT') {
        isQuitFormVisible.value = true;
      } else {
        onSaveBasicInfo();
      }
    };

    // = ---------------------------- = 车辆 = ---------------------------- =
    // 保存车辆数据
    const saveAutoInfoHandler = async () => {
      try {
        await request.put(createApiUrl('/newlinkSass/vehicle/bindVehicleStaffList'), {
          id: employId.value,
          vehicleInfoList: choisedAutoListOnShow.value.map(item => ({ id: item.id, vehicleNo: item.vehicleNo, userCode: basicFormData.value.userCode })),
          userCode: basicFormData.value.userCode,
          obdCode: obdInfos.value.obdCode,
          usingCarType: usingCarType.value,
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('车辆绑定成功');
      editCancel('isEditAutoInfo');
    };

    // 保存Obd信息
    const saveObdInfos = async () => {
      try {
        await formRef.value.validate();
        const { openingPeriodDate, firstActivationTime, actualBillingStartTime, expirationTime } = obdInfos.value;
        await request.post(createApiUrl('/newlinkSass/staff/sim/update-obd-sim-info'), {
          ...obdInfos.value,
          iccId: obdInfos.value.iccId || null,
          obdCode: obdInfos.value.obdCode || null,
          openingPeriodDate: openingPeriodDate ? moment(openingPeriodDate).valueOf() : null,
          firstActivationTime: firstActivationTime ? moment(firstActivationTime).valueOf() : null,
          actualBillingStartTime: actualBillingStartTime ? moment(actualBillingStartTime).valueOf() : null,
          expirationTime: expirationTime ? moment(expirationTime).valueOf() : null,
        });
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
      message.success('OBD管理成功');
      editCancel('isEditObdInfo');
    };

    // 车辆下拉选择改变
    watch(choisedAutoIds, (now, old) => {
      if (store.getters['User/isScheduleModel']) {
        if (
          !now.length
          || (now.length === 1 && now[0] === old[0]) // 值不变时跳过 避免赋值引发重复调用
        ) return;
        const last = now[now.length - 1];
        choisedAutoIds.value = [last];
        choisedAutoListOnShow.value = autoList.value.filter(item => (item.id === last));
      } else {
        choisedAutoListOnShow.value = autoList.value.filter(item => ((now as number[]).includes(item.id)));
      }
    });

    // 车辆 tag 关闭
    const closeAutoTag = (id: number) => {
      choisedAutoListOnShow.value = choisedAutoListOnShow.value.filter(item => item.id !== id);
      choisedAutoIds.value = (choisedAutoIds.value as number[]).filter(item => item !== id);
    };

    // 确定绑定员工制度
    const funcSaveRestInfo = async () => {
      if (restInfo.id === 0) {
        message.error('请选择员工对应的制度');
        return false;
      }

      try {
        await request.put(createApiUrl('/newlinkSass/staff/restrict/staff/bind'), {
          confNo: restInfo.id,
          userCodes: [basicFormData.value.userCode],
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('制度绑定成功');
      editCancel('isEditRestInfo');
    };

    const filterTreeNode = (inputValue: string, treeNode: any) => treeNode.props.departmentName.toLowerCase().includes(inputValue.toLowerCase());

    const rolefilterOption = (inputValue: string, option: any) => option.key.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

    return {
      /* 页面配置数据 */
      formRef,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      departmentList,
      roleList,
      autoList,
      processedAutoList,
      restrictList,
      refBaseForm,
      isEditRestInfo,
      isEditObdInfo,
      restInfo,
      iccIdList,
      obdInfos,
      handleSearch,

      drawerTitle,
      isAdministrator,

      /* 响应式数据 */
      drawerVisible,
      basicFormData,
      rulesBaseInfo,
      isEditBaseInfo,
      lineInfo,
      isEditAutoInfo,
      choisedAutoIds,
      onFilterAutoList,
      rulesObdInfo,
      moment,
      choisedAutoListOnShow,
      vehicleInfos,
      obdStatus,
      dataLoading,
      usingCarType,

      /* 确认离职弹窗 */
      isQuitFormVisible,
      onQuitFormConfirm,
      onQuitFormCancel,

      /* Func */
      showDrawer,
      fnDrawerClose,
      editCancel,
      onSubmitBasicInfo,

      saveAutoInfoHandler,
      saveObdInfos,
      closeAutoTag,
      funcSaveRestInfo,
      rolefilterOption,

      filterTreeNode,

      autoGetPopupContainer: () => document.querySelector('.autoListMultipleSelectWrap'),
    };
  },
});
