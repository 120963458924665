
import { defineComponent, reactive, ref, watch, computed, onMounted, PropType, inject, Ref } from 'vue';
import { Form, message, Modal } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';

import type { TableColumn } from '@/components/Table';
import { useScene } from '@/views/scene/utils';
import { useStore } from 'vuex';
import { useState } from '@/store';
import { settleTree } from '@/utils/illuminate';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { RuleObject } from 'ant-design-vue/es/form/interface';


const restrictConfNoList = [
  { label: '默认制度', value: 0 },
];

const tableColumns: TableColumn[] = [
  { title: '', dataIndex: 'index', slots: { customRender: 'index' } },
  { title: '姓名', dataIndex: 'userName', slots: { customRender: 'userName' }, ellipsis: true },
  // { title: '工号', dataIndex: 'jobNumber', slots: { customRender: 'jobNumber' } },
  { title: '手机号', dataIndex: 'phone', slots: { customRender: 'phone' } },
  { title: '操作', dataIndex: 'action', width: '170px', slots: { customRender: 'action' } },
];


export default defineComponent({
  name: 'AddStaff',
  components: { PlusCircleOutlined, MinusCircleOutlined },
  props: {

  },
  emits: ['complete'],
  setup(props, ctx) {
    const store = useStore();
    const state = useState();
    const enterpriseName = ref('');
    const visible = ref(false);
    const formRef = ref();
    const submiting = ref(false);
    const formData = reactive<{
      departmentIds: Array<number> | undefined;
      tempAccountInfoList: Array<any>,
    }>({
      departmentIds: undefined,
      tempAccountInfoList: [{}],
    });
    const sceneState = useScene();

    // 自定义校验
    const validatePhone = async (rule: RuleObject, value: string) => {
      if (!value) {
        return Promise.reject('请输入手机号！');
      }
      if (!/^0?(13|15|18|14|17|16|19)[0-9]{9}$/.test(value)) {
        return Promise.reject('请输入正确的手机号！');
      } 
      return Promise.resolve();
    };

    const validateJobNumber = async (rule: RuleObject, value: string) => {
      if (!/^[a-zA-Z0-9]*$/.test(value)) return Promise.reject('只能输入数字和字母！');
      return Promise.resolve();
    };
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      formData.departmentIds = undefined; // 清空部门
      if (val === '') {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };

    // 添加记录
    const addRecord = (record:any, index: number) => {
      formData.tempAccountInfoList.splice(index + 1, 0, {});
    };

    // 删除记录
    const deleteRecord = (record: any, index: number) => {
      formData.tempAccountInfoList.splice(index, 1);
    };

    const close = (flag = false, number = 0) => {
      formRef.value.resetFields();
      formData.tempAccountInfoList = [{}];
      ctx.emit('complete', flag, number);
    };

    // 取消
    const closeModal = () => {
      Modal.confirm({
        title: '确认取消？',
        content: '确认取消添加用户操作吗？已输入内容不会保留。',
        onOk() {
          close();
        },
      });
    };
    

    // 确定
    const onSubmit = async () => {
      if (formData.tempAccountInfoList.length <= 0) return message.error('请添加员工');
      try {
        await formRef.value.validate();
        submiting.value = true;
        const params = { ...formData, enterpriseId: store.state.User.currentEnterpriseId || state.enterprise.value.enterpriseId };
        const { data = {} } = await request.post(createApiUrl('/newlinkSass/prepaidCard/batchInsertTempAccount'), { ...params });
        close(true, formData.tempAccountInfoList.length);
      } catch (e: Error & any) {
        if (e.code === 500) {
          Modal.error({
            title: '添加失败',
            content: `${e.message}`,
            okText: '确认',
          });
          return;
        }
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }
    };  

    onMounted(() => {
      enterpriseName.value = store.state.User.currentEnterprise?.enterpriseName || state.enterprise.value.enterpriseName;
      sceneState.loadSceneList(store.state.User.currentEnterpriseId || state.enterprise.value.enterpriseId);
      loadEnterpriseDepartment(store.state.User.currentEnterpriseId || state.enterprise.value.enterpriseId);
    });

    return {
      formData,
      formRef,
      departmentList,
      restrictConfNoList,
      tableColumns,
      submiting,
      addRecord,
      deleteRecord,
      validatePhone,
      validateJobNumber,
      closeModal,
      visible,
      onSubmit,
      enterpriseName,
    };
  },
});
