import { defineComponent, toRaw, reactive, ref, computed } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import debounce from 'lodash/debounce';
import { parseNumberOrZero, mobileReg } from '@/utils/illuminate';
import { RootState, useState } from '@/store';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';

const labelCol = { span: 4 };
const wrapperCol = { span: 14, offset: 1 };

export function useStaff(isCreate = false) {
  const state = useState();
  const store = useStore<RootState>();
  const route = useRoute();

  const basicFormData = reactive<{
    userName: string;
    phone: string;
    roleId?: number[];
    departmentId: string;
    state: string;
    vehicleId: string[];
    jobNumber: string;
  }>({
    userName: '',
    phone: '',
    roleId: [],
    departmentId: '',
    state: 'ON',
    vehicleId: [],
    jobNumber: '',
  });

  const payFormData = reactive({
    enterprisePay: true,
    restrictState: 'INFINITE',
    finiteAmount: '',
    ruleId: '',
  });

  // 验证手机号码是否重复
  const validatedMobile: string[] = [];
  const debouncedValidatePhone = debounce(async (mobile: string, resolve: () => void, reject: (message: string) => void) => {
    if (!mobileReg.test(mobile)) {
      debouncedValidatePhone.cancel();
      return resolve();
    }

    if (validatedMobile.includes(mobile)) {
      return reject!('手机号码已存在!');
    }

    const res: any = await request.get(createApiUrl('/newlinkSass/staff/check-account-phone'), {
      params: { phone: mobile },
    });

    if (res.data) {
      return resolve();
    }

    validatedMobile.push(mobile);
    reject!('手机号码已存在!');
  }, 1000, { maxWait: 3000 });

  const createBasicInfoRules = (formRef: any): Record<string, any> => ({
    userName: [{ required: true, message: '请输入员工姓名', trigger: 'change' }],
    phone: [
      { required: true, message: '请输入正确的手机号', trigger: 'change' },
      { pattern: mobileReg, message: '请输入正确的手机号', trigger: 'change' },
      {
        validator: (rule: any, value: any) => new Promise<void>((resolve, reject) => {
          if (!isCreate) return resolve();
          debouncedValidatePhone(value, resolve, reject);
        }),
        trigger: 'change',
      },
    ],
  });

  const createPayInfoRules = (formRef: any): Record<string, any> => ({
    finiteAmount: [{
      validator: (rule: any, value: any) => (formRef.restrictState === 'FINITE' && parseNumberOrZero(value) < 0
        ? Promise.reject('请输入正整数金额') : Promise.resolve()),
      trigger: 'change',
    }],
  });

  const isEdit = computed(() => /edit$/.test(route.name as string));

  const editId = ref(0);

  const editorForm = ref<typeof Form>(null as any);

  const submitStaffData = async (data: any) => {
    // 兼容老 api 后端要求以数组形式传递 制度数据
    data.ruleId = !data.ruleId ? [] : (Array.isArray(data.ruleId) ? data.ruleId : [data.ruleId]);
    data.enterprisePay = data.enterprisePay ? 'OPEN' : 'CLOSE';
    return request.put(createApiUrl('/newlinkSass/staff/create-or-update-account'), data);
  };

  return {
    stateOptions: [
      { label: '在职', value: 'ON' },
      { label: '离职', value: 'QUIT' },
    ],
    restrictStateOptions: [
      { label: '不限', value: 'INFINITE' },
      { label: '限制', value: 'FINITE' },
    ],
    editId,
    isEdit,
    labelCol,
    wrapperCol,
    roleNameList: [],
    basicFormData,
    payFormData,
    createBasicInfoRules,
    createPayInfoRules,
    editorForm,
    submitStaffData,
  };
}
