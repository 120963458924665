import { ref } from 'vue';
import { message } from 'ant-design-vue';
import { deepReduce } from '@/utils/illuminate';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export function resetKey(tree: any[]) {
  return tree.map(item => {
    if (item.menuName) item.key = `menu-${item.key}`;
    if (item.interfaceName) item.key = `interface-${item.key}`;
    if (item.children && item.children.length) item.children = resetKey(item.children);
    return item;
  });
}

export function getOpenKeyList(tree: any[]) {
  return deepReduce<string[]>(tree, 'children', (prev, curr) => {
    if ((!curr.children || !curr.children.length) && curr.state === 'OPEN') {
      prev.push(curr.key);
    }
    return prev;
  }, []);
}

export function useRole() {
  const roleList = ref<any[]>([]);

  const loadRoleList = async () => {
    let res: any;
    try {
      const params: any = { pageSize: 100, pageNo: 1, initStatus: 'AFTER' };
      res = await request.get(createApiUrl('/newlinkSass/role/find-all-by-enterprise'), { params });
    } catch (e: Error & any) {
      return message.error(e.message);
    }

    roleList.value = res.data.list;
  };

  return {
    roleList,
    loadRoleList,
  };
}
