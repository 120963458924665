
import { defineComponent, reactive, ref, UnwrapRef, watch, getCurrentInstance } from 'vue';
import { emailReg, mobileReg } from '@/utils/illuminate';
import { message } from 'ant-design-vue';

import Verify from '@/components/Verify/index.vue';
import { useState } from '@/store';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

interface Domain {
  value: string;
  key: number;
  verfi: boolean;
}

export default defineComponent({
  name: 'InviteEmployeesModal',
  components: {
    Verify,
  },
  props: {
    addModelType: { type: String, default: 'single' },
  },
  setup(props: Record<string, any>, ctx: any) {
    const instance = getCurrentInstance()?.proxy;
    const state = useState();
    const verify = ref<any>(null as any);
    const captchaType = ref('');
    const visible = ref(false);
    const addType = ref(props.addModelType);
    const multipleErrMessage = ref('');
    const mulTipsVerfy = ref(false);
    const formRef = ref();
    const isVerfy = ref(true);
    const enterpriseId = ref(state.enterprise.value.enterpriseId);

    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 4 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 20 } },
    };

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
      },
    };
    const dynamicValidateForm: UnwrapRef<{ domains: Domain[] }> = reactive({
      domains: [
        { value: '', key: Date.now(), verfi: false },
        { value: '', key: Date.now(), verfi: false },
        { value: '', key: Date.now(), verfi: false },
        { value: '', key: Date.now(), verfi: false },
        { value: '', key: Date.now(), verfi: false },
      ],
    });
    const multipleValue = ref('');

    const show = () => {
      visible.value = true;
      instance?.$sensors.track('es_saas_staff_invite_popup_view', { page_name: '员工_邀请员工弹窗_浏览' });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    const removeDomain = (item: Domain) => {
      const index = dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        dynamicValidateForm.domains.splice(index, 1);
      }
    };

    const addDomain = () => {
      dynamicValidateForm.domains.push({
        value: '',
        key: Date.now(),
        verfi: false,
      });
    };

    watch(() => props.addModelType, () => {
      addType.value = props.addModelType;
    });

    const onSingleInputFocus = (domain: any) => {
      domain.verfi = false;
      isVerfy.value = false;
    };

    const onSingleInputBlur = (domain: any) => {
      if (domain.value.replace(new RegExp(' ', 'gm'), '') === '') {
        domain.verfi = false;
        const temp = dynamicValidateForm.domains.filter(item => item.value.replace(new RegExp(' ', 'gm'), '') !== '' && (mobileReg.test(item.value) || emailReg.test(item.value)));
        const temp2 = dynamicValidateForm.domains.filter(item => item.verfi).length > 0;
        isVerfy.value = (temp.length < 1 || temp2);
        return false;
      }
      if (!mobileReg.test(domain.value.replace(new RegExp(' ', 'gm'), '')) && !emailReg.test(domain.value.replace(new RegExp(' ', 'gm'), ''))) {
        domain.verfi = true;
      } else {
        domain.verfi = false;
      }
      isVerfy.value = dynamicValidateForm.domains.filter(item => item.verfi).length > 0;
    };

    const dialogAfterClose = () => {
      dynamicValidateForm.domains.forEach(item => {
        item.value = '';
        item.verfi = false;
      });
      multipleErrMessage.value = '';
      multipleValue.value = '';
      visible.value = false;
    };

    const checkMultipleMatch = () => {
      let mulListValue: any = multipleValue.value.replace(new RegExp('，', 'gm'), ',');
      mulListValue = mulListValue.replace(/\n/g, '').replace(new RegExp(' ', 'gm'), '');
      const mulList: any = mulListValue.split(',');
      if (!mulList[mulList.length - 1]) {
        mulList.length -= 1;
      }

      if (mulList.length > 100) {
        isVerfy.value = true;
        mulTipsVerfy.value = true;
        multipleErrMessage.value = '一次最多可邀请100人';
        return false;
      }
      let checkResult = true;
      for (let i = 0; i < mulList.length; i++) {
        const item = mulList[i];

        if (!mobileReg.test(item) && !emailReg.test(item)) {
          checkResult = false;
          break;
        }
      }

      if (!checkResult) {
        isVerfy.value = true;
        mulTipsVerfy.value = true;
        multipleErrMessage.value = '手机号码/邮箱格式错误 ';
        return false;
      }
      return true;
    };

    const checkMultipleInfo = () => {
      if (multipleValue.value.replace(new RegExp(' ', 'gm'), '') === '') {
        isVerfy.value = true;
        mulTipsVerfy.value = false;
        return false;
      }
      checkMultipleMatch();
    };

    const changeAddModel = () => {
      if (addType.value === 'single') {
        dynamicValidateForm.domains.forEach(item => {
          item.value = '';
          item.verfi = false;
        });
        // checkMultipleInfo();
      } else {
        multipleValue.value = '';
        mulTipsVerfy.value = false;
        // isVerfy.value = dynamicValidateForm.domains.filter(item => item.verfi).length > 0;
      }
      addType.value = (addType.value === 'single' ? 'multiple' : 'single');
      isVerfy.value = true;
    };

    const singleAddModelFunc = () => {
      const d: Array<string> = [];
      dynamicValidateForm.domains.forEach(item => {
        if (item.value.replace(new RegExp(' ', 'gm'), '')) d.push(item.value);
      });

      request.post(createApiUrl('/newlinkSass/invitation/sendMultiInvitations'), {
        enterpriseId: enterpriseId.value,
        channelMessage: d.join(','),
      }).then(ret => {
        message.success('邀请成功，邀请信息已发送指定邮箱/手机号码');
        dialogAfterClose();
      }).catch(err => {
        message.error(err.message);
      });
    };

    const multipleAddFunc = () => {
      if (!checkMultipleMatch()) return;

      let mulList: any = multipleValue.value.replace(new RegExp('，', 'gm'), ',');
      mulList = mulList.replace(/\n/g, '').replace(new RegExp(' ', 'gm'), '');
      if (mulList.charAt(mulList.length - 1) === ',') {
        mulList = mulList.substr(0, mulList.length - 1);
      }
      request.post(createApiUrl('/newlinkSass/invitation/sendMultiInvitations'), {
        enterpriseId: enterpriseId.value,
        channelMessage: mulList,
      }).then(() => {
        message.success('邀请成功，邀请信息已发送指定邮箱/手机号码');
        dialogAfterClose();
      }).catch(err => {
        message.error(err.message);
      });
    };

    const checkInputData = (type: string) => {
      if (type === 'single') {
        if (dynamicValidateForm.domains.filter(item => item.value.trim() === '').length === 5) {
          return false;
        }
        if (dynamicValidateForm.domains.filter(item => item.verfi).length > 0) {
          return false;
        }
        return true;
      }
      return checkMultipleMatch();
    };

    const onShow = (type: string) => {
      captchaType.value = type;
      verify.value.show();
    };

    const onVerifyPassed = () => {
      if (addType.value === 'single') {
        singleAddModelFunc();
      } else {
        multipleAddFunc();
      }
    };

    const btnSendSingleInvitations = () => {
      const res: boolean | undefined = checkInputData(addType.value);

      if (res) {
        onShow('blockPuzzle');
      }
    };

    return {
      show,
      visible,
      formRef,
      enterpriseId,
      formItemLayout,
      formItemLayoutWithOutLabel,
      dynamicValidateForm,

      resetForm,
      removeDomain,
      addDomain,
      onSingleInputFocus,
      onSingleInputBlur,
      dialogAfterClose,
      btnSendSingleInvitations,
      isVerfy,
      addType,
      multipleValue,
      changeAddModel,
      multipleErrMessage,
      checkMultipleInfo,
      verify,
      onVerifyPassed,
      onShow,
      captchaType,
      mulTipsVerfy,
    };
  },
});
