
import { defineComponent, reactive, ref, computed, onMounted } from 'vue';
import { Form, message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { useDepartment } from '@/views/main/department/utils';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';

export default defineComponent({
  name: 'ConfirmCreditModal',
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const visible = ref(false);

    const onOk = async () => {
      router.push({ name: 'profile.confirm_credit' });
    };

    const show = () => {
      visible.value = true;
    };

    return {
      visible,
      show,
      onOk,
    };
  },
});
