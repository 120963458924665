
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { Form, message } from 'ant-design-vue';
import { not } from '@/utils/illuminate';
import moment from 'moment';
import { useStaff } from '@/views/main/staff/utils';
import { useDepartment } from '@/views/main/department/utils';

import { useState } from '@/store';
import { firstError, isValidateError } from '@/utils/utils';
import { useAuto } from '@/views/auto/utils';
import { useScene } from '@/views/scene/utils';
import { useRole } from '@/views/role/utils';
import router from '@/router';

export default defineComponent<{}, {}, {}, {}, any>({
  name: 'CreateStaffView',
  setup(props, context) {
    const state = useState();
    const staffState = useStaff(true);
    const departmentState = useDepartment();
    const autoState = useAuto();
    const sceneState = useScene();
    const roleState = useRole();
    const submiting = ref(false);

    const step = ref(0);
    const title = computed(() => (step.value === 0 ? '添加员工 - 填写信息' : '添加员工 - 分配加油制度'));

    const gotoNextStep = (num: number) => {
      step.value += num;
    };

    const basicFormRef = ref<typeof Form>(null as any);
    const gotoNextStepOrSubmit = () => {
      if (submiting.value) return;

      const p = basicFormRef.value.validate();

      p.then(() => gotoNextStep(1));

      p.catch((e: any) => {
        message.error(isValidateError(e) ? firstError(e) : e.message);
      })
        .finally(() => {
          submiting.value = false;
        });
    };

    const payFormRef = ref<any>(null as any);
    const onSubmit = () => {
      if (submiting.value) return;

      payFormRef.value.validate()
        .then(() => {
          submiting.value = true;
          return staffState.submitStaffData({
            ...staffState.basicFormData,
            ...staffState.payFormData,
          });
        })
        .then(() => {
          message.success('员工添加完成');
          router.push({ name: 'main.staff.list' });
        })
        .catch((e: any) => {
          message.error(isValidateError(e) ? firstError(e) : e.message);
        })
        .finally(() => {
          submiting.value = false;
        });
    };

    onMounted(() => {
      departmentState.loadDepartmentTree();
      roleState.loadRoleList();
      autoState.loadAutoList();
      sceneState.loadSceneList();
    });

    return {
      ...not(staffState, ['createBasicInfoRules', 'createPayInfoRules']),
      ...departmentState,
      ...roleState,
      ...autoState,
      ...sceneState,
      submiting,
      title,
      step,

      basicInfoRules: staffState.createBasicInfoRules(staffState.basicFormData),
      payInfoRules: staffState.createPayInfoRules(staffState.payFormData),

      gotoNextStepOrSubmit,
      gotoNextStep,

      basicFormRef,
      payFormRef,
      onSubmit,
    };
  },
});
